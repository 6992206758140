/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import useBlockBackButton from "../../utils/preventBrowserBack";
import { useNavigate } from "react-router-dom";

const MissedChanceScreen = () => {
  const navigate = useNavigate();
  useBlockBackButton();
  useEffect(() => {
    setTimeout(() => navigate("/shop-now"), 6000);
  }, []);

  return (
    <div className="landing-content game-content timeout-page">
      <div className="header">
        <img className="logo-img" src="images/logo.png" alt="breeze logo" />
      </div>
      <img
        className="game-final-image"
        src={`images/missChance.png`}
        alt="breeze banner"
      />
    </div>
  );
};

export default MissedChanceScreen;
