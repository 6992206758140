import React from "react";
import PropTypes from "prop-types";

const CtaButton = ({ href, imageUrl, width, height }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img
        src={imageUrl}
        alt="breeze shop"
        width={width ?? 50}
        height={height ?? 50}
        style={{
          objectFit: "contain",
        }}
      />
    </a>
  );
};

CtaButton.propTypes = {
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default CtaButton;
