/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBlockBackButton from "../../utils/preventBrowserBack";
import { formatTime } from "../../utils/helper";
import CtaButton from "../ctaButton";

const WaitingGamePage = () => {
  useBlockBackButton();
  const waitTime = JSON.parse(sessionStorage.getItem("waitingTime")) || 20;
  const [secondsRemaining, setSecondsRemaining] = useState(waitTime);
  const navigate = useNavigate();

  useEffect(() => {
    if (secondsRemaining > 0) {
      const interval = setInterval(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      navigate("/game");
    }
  }, [secondsRemaining]);

  return (
    <div className="landing-content game-content waiting-page">
      <video
        src="video/mobileEnd.mp4"
        playsInline
        autoPlay
        muted
        width="900px"
        height="1640px"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "scaleX(1.033)",
          background: "#000",
        }}
      />
      <div
        className="footer"
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: "36px",
        }}
      >
        <h3>
          You are in queue. Your waiting time is {formatTime(secondsRemaining)}
        </h3>
        <div className="shop-now-footer">
          <img
            src="images/shopNow.png"
            alt="breeze shop now"
            height={16}
            className="animate-cta shop-now-text"
          />
          <CtaButton
            href="https://shopee.ph/unilever_homecare?shopCollection=10189232#product_list"
            imageUrl="images/Shopee.png"
            width={46}
            height={46}
          />
          <img src="images/divider.png" alt="breeze divider" height={36} />
          <CtaButton
            href="https://www.lazada.com.ph/unilever-homecare/?spm=a2o4l.10450891.0.0.3a9b5212XC8LAw&q=All-Products&shop_category_ids=1286915&from=wangpu&sc=KVUG&shopId=2239255 "
            imageUrl="images/lazada.png"
            width={46}
            height={46}
          />
        </div>
      </div>
    </div>
  );
};

export default WaitingGamePage;
