/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useBlockBackButton from "../../utils/preventBrowserBack";

const TimeOutScreen = () => {
  const navigate = useNavigate();
  useBlockBackButton();
  useEffect(() => {
    setTimeout(() => navigate("/shop-now"), 6000);
  }, []);
  return (
    <div className="landing-content game-content timeout-page">
      <div className="header">
        <img className="logo-img" src="images/logo.png" alt="breeze logo" />
      </div>
      <img
        className="game-final-image"
        src={`images/opsInstruction.png`}
        alt="breeze banner"
      />
    </div>
  );
};

export default TimeOutScreen;
