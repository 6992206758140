/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectSocketIO } from "../../utils/socket";
import Draggable from "react-draggable";
import useBlockBackButton from "../../utils/preventBrowserBack";
import "./game.css";

let counter = 5;

const GamePage = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const clothRef = useRef(null);
  const soapRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [counters, setCounters] = useState(5);
  const [showStainCloth, setShowStainCloth] = useState(true);
  const [showWashSoap, setShowWashSoap] = useState(true);
  const [showInstruction, setShowInstruction] = useState("drag");
  const [procedureType, setProcedureType] = useState("stainCloth");
  const [timer, setTimer] = useState(25);
  const [socketData, setSocketData] = useState(null);
  const [interacted, setInteracted] = useState(false);
  useBlockBackButton();

  const onDrop = (e, ui) => {
    if (e.target.id === "stainCloth") {
      if (ui.x > 0 && ui.x < window.innerWidth && ui.y > -800 && ui.y < -100) {
        handleSendMessage("addCloth");
        setShowStainCloth(false);
        setInteracted(true);
      }
    }
    if (e.target.id === "washSoap") {
      if (ui.x < 0 && ui.x > -window.innerWidth && ui.y > -800 && ui.y < -100) {
        handleSendMessage("addSoap");
        setProcedureType("");
        setShowWashSoap(false);
        setTimeout(() => setShowInstruction("showStart"), 2000);
      }
    }
  };

  const handleMessage = (type) => {
    if (type === "stainCloth") {
      setProcedureType("washSoap");
      return;
    }
    if (type === "washSoap") {
      setProcedureType("stainCloth");
    }
  };

  useEffect(() => {
    // Establish Socket.IO connection
    const ws = connectSocketIO();

    // Event listeners
    ws.on("connect", () => {
      console.log("Connected to socket.IO server");
    });

    ws.on("message", (data) => {
      const wsData = JSON.parse(data);
      if (wsData?.event === "game-ended") {
        navigate("/shop-now");
      }
      if (wsData?.event === "timeOut") {
        navigate("/timeout");
      }
      if (wsData?.goToHomePage === true) {
        window.location.reload();
      }
      if (wsData?.waitTime && Number(wsData?.waitTime) > 0) {
        sessionStorage.setItem("waitingTime", wsData?.waitTime);
        navigate("/waiting");
      }
      if (data) {
        setSocketData(JSON?.parse(data));
      }
    });

    ws.on("disconnect", (reason) => {
      console.log("Socket.IO connection closed", reason);
    });

    ws.on("connect_error", (error) => {
      console.error("Socket.IO connection error", error);
    });

    setSocket(ws);

    if (showInstruction === "drag") {
      setTimeout(() => {
        setShowInstruction("");
      }, 5000);
    }

    // Cleanup function to close socketIO.IO connection when component unmounts
    return () => {
      ws.disconnect();
    };
  }, []);

  const handleSendMessage = (message) => {
    if (message === "missed") {
      socket.emit("home-message", "game-missed");
      return;
    }
    socket.emit("game-message", message);
    if (message === "startWash") {
      setShowInstruction("countdown");
      const intervalId = setInterval(() => {
        counter--;
        setCounters(counter);
        if (counter < 0) {
          clearInterval(intervalId);
          setShowInstruction("");
        }
      }, 1000);
    }
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setTimer((oldTimer) => {
        if (oldTimer <= 1) {
          clearInterval(timeInterval);
          return 0;
        }
        return oldTimer - 1;
      });
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [socketData?.startTurn]);

  useEffect(() => {
    if (!interacted && timer <= 13) {
      handleSendMessage("missed");
      navigate("/missed");
      return;
    }
    if (interacted && counters <= 0) {
      navigate("/shop-now");
    }
  }, [interacted, timer]);

  return (
    <>
      {showInstruction === "drag" && (
        <div className="instruction-screen">
          <img src="images/dragInstruction.png" alt="drag instruction" />
        </div>
      )}
      {showInstruction === "showStart" && (
        <div className="instruction-screen start-btn">
          <img
            src="images/startWash.png"
            alt="start button"
            onClick={() => handleSendMessage("startWash")}
            role="presentation"
          />
        </div>
      )}
      {showInstruction === "countdown" && (
        <div className="instruction-screen start-btn">
          <img src={`images/timer${counters}.png`} alt="timer" />
        </div>
      )}

      <div className="landing-content game-content game-machine-wrapper">
        <div className="header">
          <img className="logo-img" src="images/logo.png" alt="breeze logo" />
        </div>
        <div className="timer-wrapper game-time-wrapper">
          <h6>{timer > 20 ? "20" : timer < 10 ? `0${timer}` : timer}</h6>
          <h5>TIME</h5>
        </div>
        <div className="draggable-wrapper-main" ref={wrapperRef}>
          {procedureType === ""
            ? null
            : showStainCloth && (
                <Draggable
                  defaultClassName="cloth-wrapper cloth-wrapper-bg"
                  bounds="parent"
                  onStop={onDrop}
                  nodeRef={clothRef}
                  defaultPosition={{ x: 0, y: 0 }}
                  onMouseDown={(e) => handleMessage(e.target.id)}
                >
                  <div
                    className={`cloth-wrapper-bg ${
                      procedureType === "stainCloth" ? "animate" : ""
                    }`}
                    ref={clothRef}
                  >
                    <img
                      src="images/stainShirt.png"
                      alt="stain shirt"
                      id="stainCloth"
                    />
                  </div>
                </Draggable>
              )}
          {procedureType === ""
            ? null
            : showWashSoap && (
                <Draggable
                  defaultClassName={`soap-wrapper soap-wrapper-bg`}
                  bounds="parent"
                  onStop={(e, ui) => {
                    onDrop(e, ui);
                  }}
                  nodeRef={soapRef}
                  defaultPosition={{ x: 0, y: 0 }}
                  onMouseDown={(e) => handleMessage(e.target.id)}
                  disabled={procedureType === "stainCloth"}
                >
                  <div
                    className={`soap-wrapper-bg ${
                      procedureType === "washSoap" ? "animate" : ""
                    }`}
                    ref={soapRef}
                  >
                    <img
                      src="images/breezeSoap.png"
                      alt="breeze soap"
                      id="washSoap"
                      style={{
                        opacity: procedureType === "stainCloth" ? 0.5 : 1,
                      }}
                    />
                  </div>
                </Draggable>
              )}
          <div className="footer">
            {showWashSoap && (
              <h3>
                Drag and drop the shirt and the liquid detergent into the
                washing machine
              </h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GamePage;
