/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import useBlockBackButton from "../../utils/preventBrowserBack";
import CtaButton from "../ctaButton";

const ShopPage = () => {
  useBlockBackButton();
  return (
    <div className="landing-content game-content shop-page">
      <video
        src="video/mobileEnd.mp4"
        playsInline
        autoPlay
        muted
        width="900px"
        height="1640px"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "scaleX(1.033)",
        }}
      />

      <div className="footer">
        <div className="shop-now-footer">
          <img
            src="images/shopNow.png"
            alt="breeze shop now"
            height={16}
            className="animate-cta shop-now-text"
          />
          <CtaButton
            href="https://shopee.ph/unilever_homecare?shopCollection=10189232#product_list"
            imageUrl="images/Shopee.png"
          />
          <img src="images/divider.png" alt="breeze divider" height={36} />
          <CtaButton
            href="https://www.lazada.com.ph/unilever-homecare/?spm=a2o4l.10450891.0.0.3a9b5212XC8LAw&q=All-Products&shop_category_ids=1286915&from=wangpu&sc=KVUG&shopId=2239255 "
            imageUrl="images/lazada.png"
          />
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
